import userInfoService from "../../../../services/userInfoService";
import { mapActions, mapGetters } from "vuex";
import * as types from "../../../../store/types";

export default {
  name: 'profile',
  components: {},
  props: [],
  data() {
    return {
      merchantID: localStorage.getItem("bitrahAccessToken"),
      merchantSlug: localStorage.getItem("bitrahAccessToken"),
    }
  },
  computed: {
    ...mapGetters({
      merchantsingleData: types.GET_USER_INFO,
    }),
  },
  created() {
    this.getInfoData();
  },
  mounted() {

  },
  methods: {
    ...mapActions({
      userInfoAction: types.ACTION_USER_INFO,
    }),

    
    getInfoData() {
      userInfoService
        .getUserInfo()
        .then((response) => {
          this.userInfoAction(response.data);
          // console.log("userinfo");
          // console.log(this.merchantsingleData.name);
        })
        .catch((error) => {
          this.$bvToast.toast(error.response.data.message, {
            title: this.$i18n.t("toast.errorTitle"),
            noCloseButton: true,
            variant: "custom",
            bodyClass:
              "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass:
              "dangerBackgroundColor dangerFontColor rtl text-right",
            solid: false,
          });
        })
        .finally(() => (this.loading = false));
    },
  }
}


